import React from 'react';
import Layout from '../layouts/MainLayout';
import { Helmet } from 'react-helmet-async';
import projectImage1 from '../assets/images/visolink.webp';
import projectImage2 from '../assets/images/webtopocket.webp';
import projectImage3 from '../assets/images/bumbum.webp';
import PortfolioCard from '../components/PortfolioCard';

const Portfolio = () => {
  const projects = [
    {
      image: projectImage1,
      title: 'Viso Link',
      tags: ['Django', 'Playwright', 'AWS', 'Bootstrap'],
      description: 'Web application designed to generate organic traffic, utilizing a diverse tech stack and advanced cloud services for optimal performance and scalability. This project showcases my technical versatility, including full-stack development, cloud architecture, and automation.',
      link: 'https://viso-link.com',
    },
    {
      image: projectImage2,
      title: 'Webtopocket',
      tags: ['Django', 'AWS', 'Bootstrap'],
      description: 'Redesigned website for Webtopocket, transitioning from an old to a sleek, modern interface. This enhancement focuses on accelerating site performance, improving user navigation, and updating the visual design to better align with Webtopocket services.',
      link: 'https://webtopocket.ro',
    },
    {
      image: projectImage3,
      title: 'Bum Bum',
      tags: ['Expo'],
      description: 'Fun Drinking 18+ mobile app.',
      link: '', // No link provided
    }
  ];

  return (
    <Layout showProfileCardOnMobile={false}>
      <Helmet>
        <title>Cheovari Ionut | Portfolio</title>
        <meta name="description" content="Check out my portfolio." />
        <meta name="keywords" content="Web Developer / Ex-Lawyer / Founder of Destroyed Secure" />
      </Helmet>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <PortfolioCard
              key={index}
              image={project.image}
              title={project.title}
              tags={project.tags}
              description={project.description}
              link={project.link}
              hasLink={!!project.link}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Portfolio;
