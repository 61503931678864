import { FaReact, FaGit, FaHtml5, FaCss3Alt, FaJsSquare, FaPython, FaBootstrap, FaAws } from 'react-icons/fa';
import { SiTailwindcss, SiDjango, SiPlaywright, SiStripe, SiShopify, SiExpo } from 'react-icons/si';
import Tooltip from '../components/Tooltip';
import { motion } from 'framer-motion';

const Technologies = () => {
  return (
    <>
      <h2 className='text-3xl font-bold mt-6'>Frontend</h2>
      <div className='flex space-x-4 mt-4'>
        <Tooltip text="HTML5">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaHtml5 className='text-orange-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="CSS3">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaCss3Alt className='text-blue-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="JavaScript">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaJsSquare className='text-yellow-500 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="React">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaReact className='text-cyan-500 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Tailwind CSS">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiTailwindcss className='text-teal-400 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Bootstrap">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaBootstrap className='text-purple-600 text-5xl' />
          </motion.div>
        </Tooltip>
      </div>

      <h2 className='text-3xl font-bold mt-6'>Backend</h2>
      <div className='flex space-x-4 mt-4'>
        <Tooltip text="Python">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaPython className='text-blue-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Django">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiDjango className='text-green-600 text-5xl' />
          </motion.div>
        </Tooltip>
      </div>

      <h2 className='text-3xl font-bold mt-6 '>Other Tools</h2>
      <div className='flex space-x-4 mt-4 mb-8'>
        <Tooltip text="Git">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaGit className='text-orange-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Expo">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiExpo className='text-blue-600 text-4xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="AWS">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <FaAws className='text-yellow-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Playwright">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiPlaywright className='text-green-500 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Stripe">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiStripe className='text-blue-600 text-5xl' />
          </motion.div>
        </Tooltip>
        <Tooltip text="Shopify">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <SiShopify className='text-green-600 text-5xl' />
          </motion.div>
        </Tooltip>
      </div>
    </>
  );
};

export default Technologies;
